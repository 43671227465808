<template>
  <v-main>
    <v-app-bar app dense height="30" color="blue darken-3" dark>
      <v-btn icon @click="drawer = !drawer">
        <v-icon v-if="drawer">mdi-chevron-left-circle</v-icon>
        <v-icon v-else>mdi-chevron-right-circle</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn @click="logout" icon>
        <v-icon color="error">mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app class="">
      <div class="flex justify-center items-center h-[150px]">
        <v-avatar
          size="80"
          @click="$router.push('/')"
          class="shadow-lg shadow-blue-500 hover:shadow-blue-700"
        >
          <v-img
            src="https://cdn-icons-png.flaticon.com/512/179/179310.png"
          ></v-img>
        </v-avatar>
      </div>
      <div class="flex justify-start flex-col items-center mb-5">
        <v-list-item-title class="text-h6"> Application </v-list-item-title>
        <v-list-item-subtitle>
          Application information...
        </v-list-item-subtitle>
      </div>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-group
          v-for="(link, index) in links"
          :key="`${link.title}-${index}`"
          v-model="link.active"
          no-action
        >
          <template #activator>
            <v-list-item-icon style="margin-left: -20px">
              <v-icon small left class="ml-5" v-text="link.action" />
            </v-list-item-icon>
            <v-list-item-content class="activeGroup" style="margin-left: -30px">
              <v-list-item-title style="padding: 2px 0">
                <span>{{ link.title }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item-group active-class="activeList">
            <v-list-item
              v-for="(subLink, index) in link.items"
              :key="`${subLink.title}-${index}`"
              v-model="subLink.active"
              :to="subLink.to"
            >
              <v-list-item-icon>
                <v-icon x-small left class="ml-5">
                  {{ subLink.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content style="margin-left: -25px">
                <v-list-item-title style="padding: 2px 0">
                  {{ subLink.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-overlay :value="overlay" z-index="100">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="pa-2">
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    drawer: true,
    links: [
      {
        title: "Амалииётлар",
        icon: "mdi-link",
        active: false,
        items: [
          {
            title: "Фойдаланувчи маълумотлари",
            icon: "mdi-passport",
            to: "/passports",
            active: false,
          },
          {
            title: "Фойдаланувчилар",
            icon: "mdi-account-group-outline",
            to: "/clients",
            active: false,
          },
          {
            title: "Хабар юбориш",
            icon: "mdi-message",
            to: "/messages",
            active: false,
          },
          {
            title: "Листокларни юклаш",
            icon: "mdi-cloud-upload",
            to: "/lists",
            active: false,
          },
          {
            title: "Литцавойларни юклаш",
            icon: "mdi-cloud-upload",
            to: "/route-tickets",
            active: false,
          },
        ],
      },
      {
        title: "Settings",
        icon: "mdi-settings",
        active: false,
        items: [
          {
            title: "Future",
            icon: "mdi-dots-horizontal",
            to: "/",
            active: false,
          },
        ],
      },
    ],
  }),
  methods: {
    logout() {
      if (!confirm("Dasturdan chiqishni xoxlaysizmi ?")) return;

      localStorage.removeItem("token");
      this.$router.push("/welcome");
    },
  },
  computed: {
    overlay() {
      return this.$store.getters.getOverlay;
    },
  },
};
</script>
